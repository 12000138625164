import React, {useState} from 'react'
import Layout from '../components/Layout'
import ReactDOM from 'react-dom';
import {QRCodeSVG} from 'qrcode.react';

export default () => {
  const [qrInput, setQRInput]= useState("");
  const [displayQR, setDisplayQR] = useState(false);
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [quality, setQuality] = useState("Q");

  const generateOnClick = ()=>{
    if(!qrInput){
      setDisplayErrorMessage(true)
    }
    else{
      setDisplayErrorMessage(false)
      setDisplayQR(true)
    }

  }

  return(
    <Layout
      langKey="en"
      slug="/"
    >
      <section className="hero is-fullheight">
        <div className="hero-body">
          <div className="container has-text-centered is-size-8">
              <div style={{margin: "5rem"}}>
                <h2>QR Code creator</h2>
              </div>
              <input className="input" type="text" placeholder="Enter the text to Generate QR Code" onChange={e => setQRInput(e.target.value)}></input>
              {displayErrorMessage ? <div className={"content is-size-13"} style={{color: "red", margin: "1rem"}}>
                Please enter the text to Generate QR Code
              </div> : null}
              <p>ECC Level:</p>
              <div
                className="control"
                style={{textAlign: 'center'}}
              >
                <label
                  className="radio"
                  style={{marginRight: '1rem'}}
                >
                  <input
                    type="radio"
                    value={"L"}
                    name={"level"}
                    style={{marginRight: '0.5rem'}}
                  />
                  Low (7%)
                </label>
                <label
                  className="radio"
                  style={{marginRight: '1rem'}}
                >
                  <input
                    type="radio"
                    value={"M"}
                    name={"level"}
                    style={{marginRight: '0.5rem'}}
                  />
                  Medium (15%)
                </label>
                <label
                  className="radio"
                  style={{marginRight: '1rem'}}
                >
                  <input
                    type="radio"
                    value={"Q"}
                    name={"level"}
                    style={{marginRight: '0.5rem'}}
                    checked="checked"
                  />
                  Quartile (25%)
                </label>
                <label
                  className="radio"
                  style={{marginRight: '1rem'}}
                >
                  <input
                    type="radio"
                    value={"H"}
                    name={"level"}
                    style={{marginRight: '0.5rem'}}
                  />
                  Highest (30%)
                </label>
              </div>
              <p className="content is-size-13" style={{fontWeight: "bold"}}>Recommendation: You should almost never use a quality less than M, and Q is preferred.</p>
              <button className="button is-primary" style={{margin: "1rem"}} onClick={()=>generateOnClick()}>Generate</button>

           
              
                <div style={{margin: "2rem", height: "15rem" }}>
                  {displayQR ? 
                  <QRCodeSVG style={{height: "15rem", width: "15rem"}} value={qrInput} level={quality} />
                  : null}
                </div>
               
              
          </div>

          
        </div>

      </section>
    </Layout>
  )
}
